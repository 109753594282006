<template>
  <div>
    <RestaurantCardSliderLoading v-if="isLoading" />
    <Suspense v-else>
      <RestaurantCardOverflow
        v-if="props.simple"
        v-bind="props"
        @on-card-clicked="onCardClicked"
      >
      </RestaurantCardOverflow>
      <RestaurantCardSliderMain
        v-else
        v-bind="props"
        @on-card-clicked="onCardClicked"
      ></RestaurantCardSliderMain>
      <template #fallback>
        <RestaurantCardSliderLoading />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from "vue";
import RestaurantCardSliderLoading from "./RestaurantCardSliderLoading.vue";
import type {
  RestaurantCardProps,
  RestaurantCardSliderProps,
} from "~/types/Restaurant";
const props = defineProps<RestaurantCardSliderProps>();
const RestaurantCardSliderMain = defineAsyncComponent(
  () => import("./RestaurantCardSliderMain.vue")
);
const RestaurantCardOverflow = defineAsyncComponent(
  () => import("./RestaurantCardOverflow.vue")
);

const emits = defineEmits<{
  (
    e: "on-card-clicked",
    data: { card: RestaurantCardProps; position: number | string }
  ): void;
}>();

function onCardClicked({
  card,
  position,
}: {
  card: RestaurantCardProps;
  position: number | string;
}) {
  emits("on-card-clicked", { card, position: position.toString() || "0" });
}
defineOptions({
  name: "RestaurantCardSlider",
});
</script>
